'use client';

import IconButton from '@mui/material/IconButton';
import DoubleArrow from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';

export default function ScrollButton({ index }: { index?: number }) {
  if (index !== 0) return null;

  return (
    <IconButton onClick={() => window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })}>
      <DoubleArrow
        sx={{
          color: 'white',
          fontSize: '2rem',
        }}
      />
    </IconButton>
  );
}
