'use client';

import useScreenSize from '@/hooks/useScreenSize';

export default function Block08({ hash }: { hash: string }) {
  const { height } = useScreenSize();

  return (
    <iframe
      src={`https://player.flipsnack.com?hash=${hash}`}
      width='100%'
      height={height}
      allowFullScreen
      allow='autoplay; clipboard-read; clipboard-write'
      style={{ border: 'none' }}
    />
  );
}
